import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/mark/di-net2/src/components/layout.js";
import Sidebar from "../../../../components/sidebar";
import Outcomeitem from "../../../../components/outcomelist";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`DesignCities: ACHTUNG! Berlin, Germany`}</h1>
    <h4>{`August 6–11, 2012 | Berlin, Germany`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px",
          "margin": "5vh 0",
          "objectFit": "contain"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAf/EABYBAQEBAAAAAAAAAAAAAAAAAAIAAf/aAAwDAQACEAMQAAABMsUUgpLP/8QAGRABAAMBAQAAAAAAAAAAAAAAAAECEiEi/9oACAEBAAEFAuM0eGYWpDEP/8QAFREBAQAAAAAAAAAAAAAAAAAAACH/2gAIAQMBAT8BiP/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABoQAAICAwAAAAAAAAAAAAAAAAACEDJBQqH/2gAIAQEABj8CoxVzbs5P/8QAHBAAAgICAwAAAAAAAAAAAAAAAAERYTGRQXGB/9oACAEBAAE/IYC0ZVsjnHsMrexXvT//2gAMAwEAAgADAAAAEGD/AP/EABcRAQADAAAAAAAAAAAAAAAAAAARUXH/2gAIAQMBAT8Qwij/xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQIBAT8QFtb/xAAcEAEAAwADAQEAAAAAAAAAAAABABEhMUFRobH/2gAIAQEAAT8QvWkK6Ur7Azb4NfjAihR1BAHAeBEY2HawXd3koap6uf/Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "DesignCities: ACHTUNG! Berlin, Germany",
          "title": "DesignCities: ACHTUNG! Berlin, Germany",
          "src": "/static/cfa51176837d7a4c4e7e2bc2c8ea2b29/e5166/Nordes_03.jpg",
          "srcSet": ["/static/cfa51176837d7a4c4e7e2bc2c8ea2b29/f93b5/Nordes_03.jpg 300w", "/static/cfa51176837d7a4c4e7e2bc2c8ea2b29/b4294/Nordes_03.jpg 600w", "/static/cfa51176837d7a4c4e7e2bc2c8ea2b29/e5166/Nordes_03.jpg 1200w", "/static/cfa51176837d7a4c4e7e2bc2c8ea2b29/d9c39/Nordes_03.jpg 1800w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <Sidebar mdxType="Sidebar">
    <Outcomeitem link="https://archive.designinquiry.net/contributions/rolf-pilarsky/" title="Media Interventions for a Post Neolibral City by Rolf Pilarsky" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/the-mobile-kitchen/" title="The Mobile Kitchen by Fabrizio Lamoncha and Michel Turgan" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/going-away-coming-back-again-2/" title="Going Away & Coming Back Again by Earlwyn Covington" mdxType="Outcomeitem" />
    </Sidebar>
    <p><strong parentName="p">{`In 2006, Berlin, Germany was designated by UNESCO as the first European City Of Design. From a damaged city, Berlin has evolved into a multi-faceted melting-pot, with all its clashes and conflicts, new beginnings and fresh perspectives. It is unique in its ability to adapt to difficulties and experiment with the given. The radical changes within the city are visible everywhere you go and its constant flux draws artists and designers from all over the world, inviting them to experiment with it and create something new, fill the empty spaces. Berlin is becoming more and more a city of design participation, grassroots activism and DIY-initiatives. Berlin design seldom fits a fixed category of design but rather defines itself in-between disciplines drawing from the international influence and diverse backgrounds of its protagonists. It is a place where new tendencies in design are taken up very quickly, although they might not have been initiated here. Seemingly disparate ideas and approaches get entangled and (re-)mixed into something new.`}</strong></p>
    <p><strong parentName="p">{`The notion that design practice, and the presence of designers, can alter the trajectory of a city for the better is an assumption that beseeches exploration and testing. Meeting Berlin‘s flora and fauna, getting used to its habits and communicating with its inhabitants was the aim of DesignInquiry-slash-Berlin. We were there as designers, artists, makers, exploring this design town.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      